<template>
  <v-layout v-if="_.size(detail) > 0" row wrap>
    <v-flex v-if="uploadImage" xs12 lg12 md12>
      <v-card>
        <titleCard title="Subir imagenes" subtitle="Formatos soportados jpg, jpeg, png. Tamaño maximo 1mb"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <FilePond
            ref="pond"
            class="file-pond"
            name="image"
            label-idle='Arrastra y suelta tus archivos o <span class="filepond--label-action"> Buscalos </span>'
            allow-multiple="true"
            accepted-file-types="image/jpg, image/jpeg, image/png"
            :server="{ process }"
            data-max-file-size="2MB"
            data-max-files="1"
            :files="myFiles"
            @error="onError"
          />
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Imagenes" subtitle="Imagenes disponibles en su producto"></titleCard>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :items="product.images" class="elevation-0 ma-0" hide-actions>
          <template v-slot:items="props">
            <td style="width: 80px;" class="text-xs-center">
              {{ props.item.position }}
            </td>
            <td>
              <a :href="props.item.url" target="_blank" download>
                <img :src="props.item.url" style="max-width:70px;" />
              </a>
            </td>
            <td class="text-xs-center">
              <status :value="props.item.status" @click.native="changeStatus(0, props.item)"></status>
            </td>
            <td class="text-xs-center">
              <status :value="props.item.default" @click.native="changeStatus(1, props.item)"></status>
            </td>
            <td class="text-xs-center">
              <status :value="props.item.person" @click.native="changeStatus(2, props.item)"></status>
            </td>
            <td width="200" class="text-xs-center">
              <v-btn
                :disabled="props.index === 0"
                class="ma-0"
                flat
                icon
                @click.stop="changePosition('up', props.item.id)"
              >
                <v-icon>keyboard_arrow_up</v-icon>
              </v-btn>
              <v-btn
                :disabled="props.index + 1 === product.images.length"
                class="ma-0"
                flat
                icon
                @click.stop="changePosition('down', props.item.id)"
              >
                <v-icon>keyboard_arrow_down</v-icon>
              </v-btn>
              <v-btn color="back" class="ma-0" flat icon @click="deleteImage(props.item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
        <v-divider light></v-divider>
      </v-card>
    </v-flex>
    <v-btn fixed dark fab bottom right color="accent" @click="uploadImage = !uploadImage">
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

import qs from 'qs'
import { GET_API, GET_PRODUCT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import status from '../useful/status.vue'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
  name: 'ProductImages',
  components: { titleCard, status, FilePond },
  props: ['product', 'getProduct'],
  data: () => ({
    myFiles: [],
    uploadImage: false,
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    headers: [
      { text: 'Orden', align: 'right', sortable: false },
      { text: 'Imagen', align: 'center', sortable: false },
      { text: 'Estado', align: 'center', sortable: false },
      { text: 'Portada', align: 'center', sortable: false },
      { text: 'Persona', align: 'center', sortable: false },
      { text: '', align: 'center', sortable: false }
    ]
  }),
  watch: {
    product() {
      this.detail = this.product
    }
  },
  mounted() {
    this.detail = this.product
  },
  methods: {
    async process(fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData()
      formData.append(fieldName, file)
      if (file.size < 2000000) {
        try {
          await this.$http.post(`${GET_PRODUCT}/${this.detail.id}/img`, formData, {
            headers: { 'content-type': 'multipart/form-data' }
          })
          this.getProduct()
          load('success')
        } catch (e) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error al subir el archivo'
          })
          error()
        }
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'El tamaño maximo de archivo es de 1MB'
        })
        error()
      }
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        }
      }
    },
    async changePosition(type, id) {
      try {
        const res = await this.$http.put(`${GET_PRODUCT}/${this.detail.id}/img/${id}/position`, {
          type
        })
        console.log(res.data.json)
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async changeStatus(type, item) {
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.detail.id}/img/${item.id}/status`, {
          type
        })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteImage(item) {
      try {
        await this.$http.delete(`${GET_PRODUCT}/${this.detail.id}/img/${item.id}`)
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    onError(error, file) {
      console.log(error, file)
    }
  }
}
</script>

<style>
.file-pond .filepond--root {
  margin-bottom: 0 !important;
}
</style>
